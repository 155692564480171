<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-layout wrap ma-2 py-8>
            <v-flex xs12>
                <v-switch v-model="item.enable" :label="$t('Habilitado')" />
            </v-flex>

            <v-flex xs12>
                <v-text-field v-model="item.service" :label="$t('Atendimento')" readonly />
            </v-flex>

            <v-flex xs12>
                <v-select v-model="item.method" :rules="[rules.required]" :items="methods" item-value="id" item-text="name" label="Método de pagamento"></v-select>
            </v-flex>

            <v-flex xs12>
                <v-text-field
                    v-model="item.value"
                    :rules="[rules.required, rules.money]"
                    :label="$t('Valor')"
                    v-mask="['#.##', '##.##', '###.##', '####.##', '#####.##']"
                    return-masked-value
                    prefix="R$"
                    type="number"
                    required
                />
            </v-flex>

            <v-flex xs12 md12 sm12>
                <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">Salvar</v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

import methods from "@/assets/json/methods.json";

export default {
    directives: { mask },
    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            methods,
            itemClean: {
                method: null,
                service: null,
                value: null,
            },
            item: { ...this.itemClean },
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    mounted() {
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>